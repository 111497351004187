<template>
  <div
    class="field"
    :class="{ 'is-horizontal' : labelForInput}"
  >
    <div
      v-if="label"
      class="field-label is-normal has-text-left"
    >
      <label
        ref="label"
        class="label"
      >
        {{ label }}
      </label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control has-icons-left has-icons-right">
          <textarea
            :value="value"
            :class="[{ 'is-success': hasPassedValidation, 'is-danger': hasError }, inputCssClass]"
            :placeholder="label"
            :disabled="disabledStatus"
            class="textarea"
            :maxlength="maxLength"
            @blur="onBlur"
            @keyup="onChange"
            @focus="onFocus"
            @input="onInput"
          ></textarea>
        </div>
        <div v-if="error">
          <span
            v-if="!Array.isArray(error)"
            class="help is-danger"
            v-html="error"
          ></span>
          <div
            v-else
            class="help is-danger"
          >
            <div
              v-for="(message, index) in error"
              :key="`e${index}`"
              v-html="message"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseInputFieldController from '@/components/UI/Form/BaseInputFieldController'
export default {
  name: 'UiTextarea',
  extends: BaseInputFieldController,

  data() {
    return {
      hasPassedValidation: false,
      // error: false
    }
  },
}
</script>
