<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: [String, Array],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    fieldType: {
      type: String,
      default: 'text',
    },
    maxLength: {
      type: [Number, String],
      default: 5000
    },
    labelForInput: {
      type: Boolean,
      default: true,
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
    inputCssClass: {
      type: String,
      default: '',
      note: 'Bulma class name size. is-small, is-medium, is-large. By leaving this empty the size will be set to normal.'
    },
    value: {
      type: [Object, String, Number, Date, Boolean],
      default: '',
      note: function() {
        return this.$t('Components.UI.Form.BaseInputFieldController.Value_Note')
      },
    },
    disabled: {
      type: Boolean,
      default: false,
      note: function() {
        return this.$t(
          'Components.UI.Form.BaseInputFieldController.Disabled_Note'
        )
      },
    },
    required: {
      type: Boolean,
      default: false,
      // note: 'This field is required'
      note: function() {
        return this.$t(
          'Components.UI.Form.BaseInputFieldController.Required_Note'
        )
      },
    },
    onKeyUp: {
      type: Function,
      default: function() { },
    },
  },

  data() {
    return {
      hasPassedValidation: false,
    }
  },

  computed: {
    /**
     * Either use the supplied id, or generate a unique one.
     */
    labelId() {
      return this.id === '' ? this.uniqueId() : this.id
    },
    /**
     * It is recommended to bind the <label> element through $ref, to ensure
     * the label and input id are identical.
     */
    inputId() {
      return this.$refs.label ? this.$refs.label.for : this.labelId
    },

    hasError() {
      if (
        (!Array.isArray(this.error) && this.error) ||
        (Array.isArray(this.error) && this.error.length)
      ) {
        return true
      } else {
        return false
      }
    },
  },

  methods: {
    /**
     * Generate a unique id used to couple the input and label tags
     *  This method is only used an id was not explicitly provided as a prop
     */
    uniqueId() {
      // CHeck whether the uuid is null, to avoid double generation of uuid,
      // causing a possible mismatch between input an label.
      return (this.uuid = this.uuid !== null ? this.uuid : uuidv4())
    },
    onFocus(e) {
      this.focus(e)
    },
    onBlur(e) {
      this.blur(e)
    },
    onChange(e) {
      this.change(e)
    },
    onInput(e) {
      this.input(e)
    },
    focus(e) {
      this.$emit('focus', e)
    },
    blur(e) {
      this.$emit('blur', e)
    },
    change(e) {
      let value = e.target.matches('[type="checkbox"]')
        ? e.target.checked
        : e.target.value.trim()

      this.$emit('change', value)
    },
    input(e) {
      let value = e.target.matches('[type="checkbox"]')
        ? e.target.checked
        : e.target.value.trim()

      this.$emit('input', value)
    },

    // onBlur() { },
    // onChange() {
    //   this.onKeyUp()
    // },
    // onFocus() { },
    // onInput() {
    //   this.$emit('input', event.target.value)
    // }
  },
}
</script>
